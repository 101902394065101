<template>
  <scroller>
    <NavButtonBar>
      <nav-button @action="$router.back()">
        <i class="fa-solid fa-angle-left" />
      </nav-button>
    </NavButtonBar>
    <h1 class="text-primary mt-2 mb-50">
      {{ $t('pages.plots-crop-types.name') }}
    </h1>
    <h4>{{ $t('pages.plots-crop-types.one.title') }}</h4>

    <div class="mt-0 pt-75 mb-3">
      <template v-if="error">
        <div
          class="mt-2 mb-5 text-muted text-center d-flex flex-column px-3"
        >
          <h1 class="mt-3 mb-2">
            <i class="fa-regular fa-cloud-exclamation" />
          </h1>
          {{ $t('pages.plots-crop-types.one.list.error-loading') }}

          <div>
            <TouchButton
              size="xs"
              class="mt-2"
              @action="load(true)"
            >
              {{ $t('therms.retry') }}
            </TouchButton>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="loader || crop===null">
          <div class="mt-2 d-flex align-items-center justify-content-center my-5 py-2">
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem;"
            />
          </div>
        </template>
        <template v-else>
          <div class="mt-2 d-flex flex-column">
            <NavButtonBar class="pt-0 mb-75 d-flex align-items-center">
              <nav-button
                class="mr-75"
                color="danger"
                @action="askDelete"
              >
                <i class="fa-solid fa-link-simple-slash" />
              </nav-button>
              <span>{{ $t('therms.un-assign') }}</span>
            </NavButtonBar>
          </div>

          <div class="mt-1 pb-50" />

          <b-list-group
            class="mb-3"
          >
            <b-list-group-item
              disable
              class="d-flex justify-content-between align-items-center"
            >
              <div class="col-5 col-sm-4 col-md-3 col-lg-3 p-0 text-ellipsis">
                <strong>{{ $t('therms.plot') }} :</strong>
              </div>
              <div class="col-7 col-sm-8 col-md-9 col-lg-9 p-0 text-ellipsis">
                {{ plot.name }}
              </div>
            </b-list-group-item>
            <b-list-group-item
              disable
              class="d-flex justify-content-between align-items-center"
            >
              <div class="col-5 col-sm-4 col-md-3 col-lg-3 p-0 text-ellipsis">
                <strong>{{ $t('therms.crop') }} :</strong>
              </div>
              <div class="col-7 col-sm-8 col-md-9 col-lg-9 p-0 text-ellipsis">
                {{ crop.name }}
              </div>
            </b-list-group-item>
            <b-list-group-item
              disable
              class="d-flex justify-content-between align-items-center"
            >
              <div class="col-5 col-sm-4 col-md-3 col-lg-3 p-0 text-ellipsis">
                <strong>{{ $t('therms.crop-type') }} :</strong>
              </div>
              <div class="col-7 col-sm-8 col-md-9 col-lg-9 p-0 text-ellipsis">
                {{ cropType.name }}
              </div>
            </b-list-group-item>
          </b-list-group>

          <div class="mb-2" />
        </template>
      </template>
    </div>
  </scroller>
</template>

<script>
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import ListGroupItem from '@core/components/list-group/ListGroupItem.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import { BListGroup, BSpinner } from 'bootstrap-vue'
import { Dialog } from '@capacitor/dialog'
// eslint-disable-next-line import/no-cycle

export default {
  components: {
    TouchButton,
    NavButton,
    NavButtonBar,
    BListGroup,
    'b-list-group-item': ListGroupItem,
    BSpinner,
  },
  data() {
    return {
      // ui
      error: false,
      loader: false,

      // data
      crop: null,
      cropType: null,
      plot: null,
    }
  },
  computed: { },
  async mounted() {
    this.error = false
    await this.load()
  },
  methods: {
    async askDelete() {
      const { value } = await Dialog.confirm({
        title: this.$t('therms.deleting').toString(),
        message: this.$t('actions.plot-crop-assign-ask.message').toString(),
        okButtonTitle: this.$t('therms.confirm').toString(),
        cancelButtonTitle: this.$t('therms.cancel').toString(),
      })

      if (value) {
        this.loader = true
        if (!await this.$store.dispatch('crops/deletePlotOnCropType', {
          cropId: this.crop.id,
          cropTypeId: this.cropType.id,
          plotId: this.plot.id,
        })) {
          await Dialog.alert({
            title: this.$t('therms.deleting').toString(),
            message: this.$t('actions.plot-crop-un-assign-error.message').toString(),
            buttonTitle: this.$t('therms.close').toString(),
          })

          this.loader = false
          return
        }

        await this.$router.back()
      }
    },
    /**
     * @param {Boolean} force
     * @returns {Promise<void>}
     */
    async load(force = false) {
      this.loader = true
      try {
        if (force) {
          this.crop = null
          this.cropType = null
          this.plot = null
        }

        // eslint-disable-next-line radix
        this.crop = await this.$store.dispatch('crops/find', { id: parseInt(this.$router.currentRoute.params?.cropId) })
        // eslint-disable-next-line radix,prefer-destructuring
        this.cropType = this.crop.types.filter(ct => ct.id === parseInt(this.$router.currentRoute.params?.cropTypeId))[0]
        // eslint-disable-next-line radix
        this.plot = await this.$store.dispatch('plots/find', { id: parseInt(this.$router.currentRoute.params?.plotId) })
      } catch (e) {
        this.error = true
        throw e
      } finally {
        this.loader = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

</style>
